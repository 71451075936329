import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

const AccessDeclaration = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;
  return (
    <Layout>
      <div className="section-container">

        <section className="section">
          <h1>{frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </section>
      </div>
    </Layout>
  );
}

export default AccessDeclaration;

export const query = graphql`
  query AccessDeclaration {
    markdownRemark(frontmatter: {templateKey: {eq: "access-declaration-page"}}) {
      html
      frontmatter {
        title
      }
    }
  }
`
